import React, { useEffect, useState } from 'react'
import {
  Route,
  useLocation,
  Routes,
  useNavigate,
  Navigate,
} from 'react-router-dom'
import LeadRouter from '../container/leadRouter'
import LeadRouterDetail from '../container/RouterDetail'
import SourcesTemplate from 'src/components/templates/sources/Sources.Template'
import SourceDetailsTemplate from 'src/components/templates/sources/_id/SourceDetails.Template'
import { useAppSelector } from 'src/store'
import { getSubscriptionStatus } from 'src/container/my_account/account.selector'
import Loader from 'src/General/views/Loader'
import TrialExpired from 'src/components/templates/subscriptions/TrialExpired'
import { SUBSCRIPTION_STATUS } from 'src/container/my_account/account.slice'
import TrialAboutToExpire from 'src/components/templates/subscriptions/TrialAbtToExpire'
import { useActions } from 'src/actions'
import Profile from 'src/container/profile/profile'
import Account from 'src/container/account/account'
import Users from 'src/container/users/users'
import CapacitiesPage from 'src/container/capacities/capacities'
import BillingTab from 'src/container/billing/billing'
import Integrations from 'src/container/integrations/index'
import Conversions from 'src/container/conversions/index'
import ConversionsDetail from 'src/container/conversions/conversionsDetail'
import Dashboard from 'src/container/dashboard/dashboard'
import Roles from 'src/container/roles/roles'
import CreateRole from 'src/components/organisms/createRole/createRole'
import { AbilityContext, getAbilities } from 'src/utils/permissionsCASL'
import { AbilityTuple, MongoAbility, MongoQuery } from '@casl/ability'
import Sla from 'src/container/SLA'
import SLADetail from 'src/container/SLA/SLADetail'
import OutlookCalendar from 'src/components/molecules/outlookCalendar/outlookCalendar'

const AuthenticatedRoutes: React.FC = () => {
  const location = useLocation()
  const [showTrialExpiry, setShowTrialExpiry] = useState(false)
  const subscriptionStatus: string = useAppSelector(getSubscriptionStatus)
  const { getPlansAction, getUserPermissionsAction } = useActions()
  const navigate = useNavigate()
  const [abilities, setAbilities] = useState<MongoAbility<
    AbilityTuple,
    MongoQuery
  > | null>(null)

  const loadCASLPermission = async () => {
    const permissions = await getAbilities(getUserPermissionsAction)
    setAbilities(permissions)
  }

  useEffect(() => {
    getPlansAction()
    loadCASLPermission()
  }, [])

  if (location.pathname === '/') {
    navigate('/settings/profile')
  }

  useEffect(() => {
    if (
      [SUBSCRIPTION_STATUS.TRIAL_ABOUT_EXPIRE].includes(
        subscriptionStatus as SUBSCRIPTION_STATUS,
      )
    ) {
      setShowTrialExpiry(true)
    } else {
      setShowTrialExpiry(false)
    }
  }, [subscriptionStatus])

  if (!subscriptionStatus) {
    return <Loader display={true} />
  }

  if (
    [
      SUBSCRIPTION_STATUS.TRIAL_EXPIRED,
      SUBSCRIPTION_STATUS.PLAN_EXPIRED,
    ].includes(subscriptionStatus as SUBSCRIPTION_STATUS)
  ) {
    return (
      <>
        <TrialExpired />
      </>
    )
  }
  return (
    <>
      {showTrialExpiry && <TrialAboutToExpire />}
      {abilities ? (
        <AbilityContext.Provider value={abilities}>
          <Routes location={location}>
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/sources' element={<SourcesTemplate />} />
            <Route path='/settings/profile' element={<Profile />} />
            <Route path='/settings/account' element={<Account />} />
            <Route path='/settings/users' element={<Users />} />
            <Route path='/settings/capacities' element={<CapacitiesPage />} />
            <Route path='/routers' element={<LeadRouter />} />
            <Route path='/conversions' element={<Conversions />} />
            <Route
              path='/conversions/:conversionId'
              element={<ConversionsDetail />}
            />
            <Route path='/settings/billing' element={<BillingTab />} />
            <Route path='/settings/integrations' element={<Integrations />} />
            <Route
              path='/routers/:routerId/:tab'
              element={<LeadRouterDetail />}
            />
            <Route
              path='/sources/:sourceId'
              element={<SourceDetailsTemplate />}
            />
            <Route path='/settings/roles' element={<Roles />} />
            <Route
              path='/settings/create-role/:roleId'
              element={<CreateRole />}
            />
            <Route
              path='/settings/edit-role/:roleId'
              element={<CreateRole />}
            />
            <Route
              path='/settings/edit-role/:roleId'
              element={<CreateRole />}
            />
            <Route path='/slas' element={<Sla />} />
            <Route path='/slas/:slaId' element={<SLADetail />} />
            <Route path='/oauth/callback' element={<OutlookCalendar />} />

            <Route path='*' element={<Navigate to='/settings/profile' />} />
          </Routes>
        </AbilityContext.Provider>
      ) : (
        <Loader display={true} />
      )}
    </>
  )
}

export default AuthenticatedRoutes
