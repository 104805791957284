import { useEffect, useState } from 'react';
import { useActions } from 'src/actions';
import toastBuilder from 'src/utils/toastBuilder';
import { useNavigate } from 'react-router-dom';
import Loader from 'src/General/views/Loader';

const OutlookCalendar = () => {
	const [code, setCode] = useState<string>();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const { integrationCalendarAction } = useActions()

	const getCode = () => {
		if (window && window.location.search) {
			const url = window.location.search.substring(1);
			const urlParams = url && url.split('&');
			const newCode = urlParams && urlParams.find((x) => x.startsWith('code='));

			if (newCode) {
				setCode(newCode.substring(5));
			}
		}
	}

	const connectCalendar = async (code: string) => {
		setLoading(true);
		const data = {
			code,
			calendarType: 'outlook'
		};

		const results: any = await integrationCalendarAction(data);
		if (results.payload.success) {
			setLoading(false);
			toastBuilder('success', results.payload.success)
			navigate('/settings/profile?tab=working-hours');
		}
	}

	useEffect(() => {
		getCode();
	}, [])

	useEffect(() => {
		if (code) {
			connectCalendar(code);
		}
	}, [code])


	return (
		<div>{loading && (<Loader display={loading} />)}</div>
	)
}

export default OutlookCalendar